var React = require('react');

function ArrowRoundedRight6x9 (props) {
    return React.createElement("svg",props,React.createElement("path",{"d":"M0.4,8.8c-0.4-0.4-0.5-1-0.1-1.4l3-2.9l-3-2.9c-0.4-0.4-0.4-1.1,0.1-1.4c0.4-0.3,0.9-0.3,1.3,0.1L6,4.5L1.6,8.7C1.3,9.1,0.7,9.1,0.4,8.8z"}));
}

ArrowRoundedRight6x9.defaultProps = {"width":"6","height":"9"};

module.exports = ArrowRoundedRight6x9;

ArrowRoundedRight6x9.default = ArrowRoundedRight6x9;
