var React = require('react');

function ArrowRoundedDown9x6 (props) {
    return React.createElement("svg",props,React.createElement("path",{"d":"M0.2,0.4c0.4-0.4,1-0.5,1.4-0.1l2.9,3l2.9-3c0.4-0.4,1.1-0.4,1.4,0.1c0.3,0.4,0.3,0.9-0.1,1.3L4.5,6L0.3,1.6C-0.1,1.3-0.1,0.7,0.2,0.4z"}));
}

ArrowRoundedDown9x6.defaultProps = {"width":"9","height":"6"};

module.exports = ArrowRoundedDown9x6;

ArrowRoundedDown9x6.default = ArrowRoundedDown9x6;
