var React = require('react');

function Cart20 (props) {
    return React.createElement("svg",props,[React.createElement("metadata",{"id":"metadata14","key":0},React.createElement("rdf:RDF",null,React.createElement("cc:Work",{"rdfAbout":""},[React.createElement("dc:format",{"key":0},"image/svg+xml"),React.createElement("dc:type",{"rdfResource":"http://purl.org/dc/dcmitype/StillImage","key":1})]))),React.createElement("defs",{"id":"defs12","key":1}),React.createElement("sodipodi:namedview",{"pagecolor":"#ffffff","bordercolor":"#666666","borderopacity":"1","objecttolerance":"10","gridtolerance":"10","guidetolerance":"10","inkscapePageopacity":"0","inkscapePageshadow":"2","inkscapeWindowWidth":"1878","inkscapeWindowHeight":"1016","id":"namedview10","showgrid":"false","inkscapeZoom":"11.8","inkscapeCx":"12.372881","inkscapeCy":"10","inkscapeWindowX":"42","inkscapeWindowY":"27","inkscapeWindowMaximized":"1","inkscapeCurrentLayer":"svg8","key":2}),React.createElement("circle",{"cx":"7","cy":"17","r":"2","id":"circle2","style":{"fill":"#e94545","fillOpacity":"1"},"key":3}),React.createElement("circle",{"cx":"15","cy":"17","r":"2","id":"circle4","style":{"fill":"#e94545","fillOpacity":"1"},"key":4}),React.createElement("path",{"d":"M20,4.4V5l-1.8,6.3c-0.1,0.4-0.5,0.7-1,0.7H6.7c-0.4,0-0.8-0.3-1-0.7L3.3,3.9C3.1,3.3,2.6,3,2.1,3H0.4C0.2,3,0,2.8,0,2.6  V1.4C0,1.2,0.2,1,0.4,1h2.5c1,0,1.8,0.6,2.1,1.6L5.1,3l2.3,6.8c0,0.1,0.2,0.2,0.3,0.2h8.6c0.1,0,0.3-0.1,0.3-0.2l1.3-4.4  C17.9,5.2,17.7,5,17.5,5H9.4C9.2,5,9,4.8,9,4.6V3.4C9,3.2,9.2,3,9.4,3h9.2C19.4,3,20,3.6,20,4.4z","id":"path6","style":{"fill":"#e94545","fillOpacity":"1"},"key":5})]);
}

Cart20.defaultProps = {"xmlnsDc":"http://purl.org/dc/elements/1.1/","xmlnsCc":"http://creativecommons.org/ns#","xmlnsRdf":"http://www.w3.org/1999/02/22-rdf-syntax-ns#","xmlnsSvg":"http://www.w3.org/2000/svg","xmlnsSodipodi":"http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd","xmlnsInkscape":"http://www.inkscape.org/namespaces/inkscape","width":"20","height":"20","version":"1.1","id":"svg8","sodipodiDocname":"cart-20.svg","inkscapeVersion":"0.92.4 (5da689c313, 2019-01-14)"};

module.exports = Cart20;

Cart20.default = Cart20;
