// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';
import { withPrefix } from 'gatsby';
// application
// import {
//     Fi24Hours48Svg,
//     FiFreeDelivery48Svg,
//     FiPaymentSecurity48Svg,
//     FiTag48Svg,
// } from '../../svg';


export default function BlockFeatures(props) {
    const { layout } = props;

    return (
        <div className={`block block-features block-features--layout--${layout}`}>
            <div className="container">
                <div className="block-features__list">
                    <div className="block-features__item">
                        <div className="block-features__icon">
                        <img src={withPrefix('images/png/delivery.png')} style={{width:"50px"}} />
                        </div>
                        <div className="block-features__content">
                            <div className="block-features__title">Next Day Delivery</div>
                            <div className="block-features__subtitle">For all orders</div>
                        </div>
                    </div>
                    <div className="block-features__divider" />
                    <div className="block-features__item">
                        <div className="block-features__icon">
                        <img src={withPrefix('images/png/heart.png')} style={{width:"50px"}} />                            
                        </div>
                        <div className="block-features__content">
                            <div className="block-features__title">Raw Advice</div>
                            <div className="block-features__subtitle">Contact us anytime</div>
                        </div>
                    </div>
                    <div className="block-features__divider" />
                    <div className="block-features__item">
                        <div className="block-features__icon">
                        <img src={withPrefix('images/png/award.png')} style={{width:"50px"}} />                            
                        </div>
                        <div className="block-features__content">
                            <div className="block-features__title">Award Winning</div>
                            <div className="block-features__subtitle">No fillers or preservatives</div>
                        </div>
                    </div>
                    <div className="block-features__divider" />
                    <div className="block-features__item">
                        <div className="block-features__icon">
                        <img src={withPrefix('images/png/handmade.png')} style={{width:"50px"}} />                            
                        </div>
                        <div className="block-features__content">
                            <div className="block-features__title">Handmade</div>
                            <div className="block-features__subtitle">in the heart of Wiltshire</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

BlockFeatures.propTypes = {
    layout: PropTypes.oneOf(['classic', 'boxed']),
};

BlockFeatures.defaultProps = {
    layout: 'classic',
};
