var React = require('react');

function Cross20 (props) {
    return React.createElement("svg",props,React.createElement("path",{"d":"M17.71,17.71L17.71,17.71c-0.387,0.387-1.013,0.387-1.4,0L10,11.4l-6.31,6.31c-0.387,0.387-1.013,0.387-1.4,0l0,0\r\n\tc-0.387-0.387-0.387-1.013,0-1.4L8.6,10L2.29,3.69c-0.387-0.387-0.387-1.013,0-1.4l0,0c0.387-0.387,1.013-0.387,1.4,0L10,8.6\r\n\tl6.31-6.31c0.387-0.387,1.013-0.387,1.4,0l0,0c0.387,0.387,0.387,1.013,0,1.4L11.4,10l6.31,6.31\r\n\tC18.097,16.697,18.097,17.323,17.71,17.71z"}));
}

Cross20.defaultProps = {"width":"20","height":"20"};

module.exports = Cross20;

Cross20.default = Cross20;
