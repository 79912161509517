var React = require('react');

function ArrowRoundedDown12x7 (props) {
    return React.createElement("svg",props,React.createElement("path",{"d":"M0.286,0.273 L0.286,0.273 C-0.070,0.629 -0.075,1.204 0.276,1.565 L5.516,6.993 L10.757,1.565 C11.108,1.204 11.103,0.629 10.747,0.273 L10.747,0.273 C10.385,-0.089 9.796,-0.086 9.437,0.279 L5.516,4.296 L1.596,0.279 C1.237,-0.086 0.648,-0.089 0.286,0.273 Z"}));
}

ArrowRoundedDown12x7.defaultProps = {"width":"12px","height":"7px"};

module.exports = ArrowRoundedDown12x7;

ArrowRoundedDown12x7.default = ArrowRoundedDown12x7;
