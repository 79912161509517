import React from 'react'
import { Link } from 'gatsby'
import SEO from '../components/SEO'

// // blocks
import BlockBanner from '../components/Blocks/BlockBanner'
// import BlockBrands from '../blocks/BlockBrands';
// import BlockCategories from '../blocks/BlockCategories';
import BlockFeatures from '../components/Blocks/BlockFeatures';
import BlockPosts from '../components/Blocks/BlockPosts';
// import BlockProductColumns from '../blocks/BlockProductColumns';
// import BlockProducts from '../blocks/BlockProducts';
// import BlockSlideShow from '../components/Blocks/BlockSlideShow';
// import BlockTabbedProductsCarousel from '../blocks/BlockTabbedProductsCarousel';

// // data stubs
// import categories from '../../data/shopBlockCategories';
// import posts from '../../data/blogPosts';
// import products from '../../data/shopProducts';
// import theme from '../../data/theme';

// const posts = [{
//   id: 1,
//   title: 'A Variety Of Other Academic And Non-Academic Approaches Have Been Explored',
//   image: 'images/posts/post-1.jpg',
//   categories: ['Special Offers'],
//   date: 'October 19, 2019',
// },
// {
//   id: 2,
//   title: 'Logic Is The Study Of Reasoning And Argument Part 2',
//   image: 'images/posts/post-2.jpg',
//   categories: ['Latest News'],
//   date: 'September 5, 2019',
// }];

const IndexPage = () => (
  <>
    <SEO
      title="Buy Raw Dog Food Online"
      keywords={[`raw dog food`, `poppys picnic`, `frozen dog food`]}
    />

    
    {/*<BlockSlideShow />*/}

    <div className="block-slideshow block block-slideshow--layout--full">
        <div className="container">
            <div className="row">
                <div className="col-12 col-lg-12">
                    <div className="block-slideshow__body">
                        <div className="slick-slider slick-initialized">
                            <div className="slick-list">
                                <div className="slick-track1" style={{"maxWidth": "1110px", opacity: "1", transform: "translate3d(0px, 0px, 0px)" }}>
                                    <div className="slick-slide slick-active slick-current" style={{outline: "currentcolor none medium", "maxWidth": "1110px" }}>
                                        <div>
                                            <div className="block-slideshow__slide" tabIndex="-1" style={{width: "100%", display: "inline-block"}}>
                                                <div className="block-slideshow__slide-image block-slideshow__slide-image--desktop" style={{ "backgroundImage": "url(images/jpg/slide1.jpg)"}}></div>
                                                <div className="block-slideshow__slide-image block-slideshow__slide-image--mobile" style={{ "backgroundImage": "url(images/jpg/slide1.jpg)"}}></div>
                                                <div className="block-slideshow__slide-content">
                                                    <div className="block-slideshow__slide-title">Let's Feed Your Dog Healthier</div>
                                                    <div className="block-slideshow__slide-text">We provide real dog food made from top quality raw ingredients, just like nature intended.</div>
                                                    <div className="block-slideshow__slide-button"><a className="btn btn-primary btn-lg" href="https://www.amazon.co.uk/s?k=frozen+dog+food&rh=n%3A471400031&dc&qid=1610015549&rnid=1642204031&tag=radofo-21&ref=sr_nr_n_1" target="_blank">Shop Now</a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    
    <BlockFeatures layout="boxed" />
    {/*
    <BlockTabbedProductsCarousel title="Featured Products" layout="grid-5" rows={2} />
    */}
    <BlockBanner />
    {/*
    <BlockCategories title="Popular Categories" layout="compact" categories={categories} />
    */}
    {/*<BlockPosts title="Latest News" layout="grid-nl" posts={posts} />*/}

    
  </>
)

export default IndexPage
