var React = require('react');

function Menu18x14 (props) {
    return React.createElement("svg",props,React.createElement("path",{"d":"M-0.000,8.000 L-0.000,6.000 L18.000,6.000 L18.000,8.000 L-0.000,8.000 ZM-0.000,-0.000 L18.000,-0.000 L18.000,2.000 L-0.000,2.000 L-0.000,-0.000 ZM14.000,14.000 L-0.000,14.000 L-0.000,12.000 L14.000,12.000 L14.000,14.000 Z"}));
}

Menu18x14.defaultProps = {"width":"18px","height":"14px"};

module.exports = Menu18x14;

Menu18x14.default = Menu18x14;
