// react
import React from 'react';

// third-party
import { Link } from 'gatsby';
import { withPrefix } from 'gatsby'


export default function BlockBanner() {
    return (
        <div className="block block-banner">
            <div className="container">
                <div className="block-banner__body">
                    <div
                        className="block-banner__image block-banner__image--desktop"
                        style={{ backgroundImage: 'url("images/jpg/ppfood.jpg")', opacity: '0.2' }}
                    />
                    <div
                        className="block-banner__image block-banner__image--mobile"
                        style={{ backgroundImage: 'url("images/jpg/ppfoodmobile.jpg")', opacity: '0.2' }}
                    />
                    <div className="block-banner__title">
                        Get Started Today
                    </div>
                    <div className="block-banner__text">
                        Next day delivery when ordered Mon-Thus before noon.
                    </div>
                    <div className="block-banner__button">
                        <a className="btn btn-sm btn-primary" href="https://www.amazon.co.uk/s?k=frozen+dog+food&rh=n%3A471400031&dc&qid=1610015549&rnid=1642204031&tag=radofo-21&ref=sr_nr_n_1" target="_blank">Shop Now</a>
                    </div>
                </div>
            </div>
        </div>
    );
}
