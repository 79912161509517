var React = require('react');

function Cross12 (props) {
    return React.createElement("svg",props,React.createElement("path",{"d":"M10.8,10.8L10.8,10.8c-0.4,0.4-1,0.4-1.4,0L6,7.4l-3.4,3.4c-0.4,0.4-1,0.4-1.4,0l0,0c-0.4-0.4-0.4-1,0-1.4L4.6,6L1.2,2.6\r\n\tc-0.4-0.4-0.4-1,0-1.4l0,0c0.4-0.4,1-0.4,1.4,0L6,4.6l3.4-3.4c0.4-0.4,1-0.4,1.4,0l0,0c0.4,0.4,0.4,1,0,1.4L7.4,6l3.4,3.4\r\n\tC11.2,9.8,11.2,10.4,10.8,10.8z"}));
}

Cross12.defaultProps = {"width":"12","height":"12"};

module.exports = Cross12;

Cross12.default = Cross12;
